import React, { useEffect }  from 'react';
import { Header, Footer, Container, JS } from 'im-ui-pack';
import { requestSwitcherAPI, ContentTypes } from '../../store/api/api';
import { ENV, PREFIX_URL } from '../../store/constants';
import sessionLinks from '../../store/mock/sessionLinks.json';

// const session_token = 'rb-eyJhcHBsaWNhdGlvbiI6InJlYmFpbGEiLCJhY2NvdW50Ijp7ImlkIjoidzlZbkhoelVuWkV3b01qaFNqQ0NqdyIsImVtYWlsIjoidGVzdHoyQGdtYWlsLmNvbSIsImVtYWlsX2xvZ2luIjoidGVzdHoyQGdtYWlsLmNvbSIsImZpcnN0bmFtZSI6IlRlc3R6MiIsImxhc3RuYW1lIjoiVGVzdCIsIm9uYm9hcmRpbmciOiJlbXB0eSJ9LCJ0b2tlbnMiOnsiYXBpX3BheW1lbnQiOiIxMTExMSIsImFwaV9hY2NvdW50IjoiMjIyMjIifSwicGF5bWVudHMiOnsic3RhdHVzIjoiYWN0aXZlIn19';
// JS.setCookie({cname:'session_token', cvalue: session_token});

console.log('ENV: ', ENV);

// Validate payment
function validatePayment(current_period_end:any) {
  const newDate = new Date();
  const today = Math.round(newDate.getTime()/1000.0);

  console.log('current_period_end:',current_period_end);
  // Subscription current_perdiod_end is not defined
  if(current_period_end==='undefined'){
    // Redirect to first payment page.
    window.location.href = `https://${PREFIX_URL}payment.Oryon.com`;
  } else if(today > current_period_end) {
    // Redirect to renew payment
    window.location.href = `https://${PREFIX_URL}payment.Oryon.com/improve`;
  } else {
    console.log('The payment is ok!');
    // window.location.href = "http://play.Oryon.com";
  }
}

function PrivateLayout(props: any) {
  const session = JS.getSession();

  useEffect(() => {
    console.log('session: ', session);
    if(session.account.email) {
      // Check if payment is ok
      checkPaymentStatus();
    } else {
      // No session setted
      window.location.href = `https://${PREFIX_URL}account.Oryon.com`;
    }
  });

  // Check payment
  async function checkPaymentStatus() {
    const data = {
      "email": session.account.email
    };
    const endpoint = `/get-payment`;
    try {
      const response = await requestSwitcherAPI('PAYMENT','POST', endpoint, data, { 'content-type': ContentTypes.json });
      const {current_period_end} = response.data;

      validatePayment(current_period_end);
    } catch (err) {
      console.log(err)
    }
  };

  return (
    <div className="public-layout">
      <Header sessionLinks={sessionLinks}/>
      <Container>
        {props.children}
      </Container>
      <Footer />
    </div>
  );
}

export default PrivateLayout