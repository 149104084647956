import React, { useEffect, useState } from 'react';
// import { Link } from 'react-router-dom';
// import { useDispatch } from 'react-redux';
// import { setItems } from '../../../store/items/ItemsActions';
// import { getStatus } from '../../../store/api/api';
import { request, requestSwitcherAPI, ContentTypes } from '../../../store/api/api';
import { useTranslation } from "react-i18next";
import { Section, Row, Col, Button, Input, H2, H3, H5, Notification, LineDivisor, JS } from 'ou-ui-pack';
import './Profile.css';
import { PREFIX_URL } from '../../../store/constants';

import axios from 'axios';

function Profile(props) {
  const { t } = useTranslation('common');
  const session = JS.getSession();
  const [currentPerdiodEnd, setCurrentPerdiodEnd] = useState('');
  const [editUserData, setEditUserData] = useState(false);
  const [editPasswordData, setEditPasswordData] = useState(false);
  const [updateSession, setUpdateSession] = useState(false);
  const [subscriptionPlan, setSubscriptionPlan] = useState();
  const [canceledSubscription, setCanceledSubscription] = useState(false);
  // const { items } = useSelector((state: any) => state.default.items)
  // const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    email: session.account.email,
    firstname: session.account.firstname,
    lastname: session.account.lastname
  });
  const [notification, setNotification] = useState({status: false, style: '', message: ''});
  // const [accountCreated, setAccountCreated] = useState();

  const handleInputChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name] : event.target.value
    })
  }

  useEffect(() => {
    if(session.account.email) {
      // Check if payment is ok
      checkPaymentStatus();
    }
    console.log('session: ',session);
    console.log('subscriptionPlan: ',subscriptionPlan);
    // console.log('currentPerdiodEnd: ',currentPerdiodEnd);
  });

  // Check payment
  async function checkPaymentStatus() {
    const data = {
      "email": session.account.email
    };
    const endpoint = `/get-payment`;
    try {
      const response = await requestSwitcherAPI('PAYMENT','POST', endpoint, data, { 'content-type': ContentTypes.json });
      const {current_period_end, stripe_subscription_id} = response.data;
      const date_period_end = new Date(current_period_end*1000);
      const dd = String(date_period_end.getDate()).padStart(2, '0');
      const mm = String(date_period_end.getMonth() + 1).padStart(2, '0'); //January is 0!
      const yyyy = date_period_end.getFullYear();
      // console.log('get-payment: response:', response);
      getSubscription(stripe_subscription_id);
      const date_period_end_formated = `${dd}/${mm}/${yyyy}`;
      setCurrentPerdiodEnd(date_period_end_formated);
      
    } catch (err) {
      console.log(err)
    }
  };

  async function updateAccount() {
    // event.preventDefault();
    const data = {
      "email": formData.email,
      "firstname": formData.firstname,
      "lastname": formData.lastname
    };
    const endpoint = `/update-account`;
    try {
      const res = await request('POST', endpoint, data, { 'content-type': ContentTypes.json });
      const { result } = res.data;
      console.log(res);
      if (result) {
        console.log(result);
        setNotification({
          status: true,
          className: 'success',
          message: 'Los datos se han modificado correctamente.'
        });
        session.account.firstname = data.firstname;
        session.account.lastname = data.lastname;
        console.log('session updated', session);
        const user = session.account;
        const session_token = 'rb-'+JS.setSession({ user });
        // Save Cookie to local machine
        JS.setCookie({cname:'session_token', cvalue: session_token});
        // Store sessionStorage for access to iframes from externals domains
        sessionStorage.setItem('session_token', session_token);
        setUpdateSession(true);
        return true;
        // props.history.push('/login')
      } else {
        setNotification({
          status: true,
          className: 'danger',
          message: 'Error al ingresar los datos.'
        });
        console.log('res: ',res);
        return false;
      }
    } catch (err) {
      console.log(err)
      setNotification({
        status: true,
        className: 'danger',
        message: 'Error al ingresar los datos.'
      });
    }
    // dispatch(signUp(data));
  }

  /*
  if(accountCreated) {
    return (
      <Section maxWidth='small'>
        <Row>
          <Col><H3>{t('sign_up.title_verify')}</H3></Col>
        </Row>
        <Row>
          <Col>
            {t('sign_up.description_verify')}
          </Col>
        </Row>
        <Row>
          <Col>
            <Link to='/login'>Ir a login</Link>
          </Col>
        </Row>
      </Section>
    )
  }
    */
  function setSessions() {
    const iframe_preload_payment = `<iframe src='https://${PREFIX_URL}payment.Oryon.com/session/index.html' />`;
    const iframe_preload_play = `<iframe src='https://${PREFIX_URL}play.Oryon.com/session/index.html' />`;

    setTimeout(function () {
      console.log('set sessions');
      window.location.href = "#";
    }, 3000);

    return (
      <div>
        <div className='display-none' dangerouslySetInnerHTML={{ __html: " "+iframe_preload_payment+" "}} />
        <div className='display-none' dangerouslySetInnerHTML={{ __html: " "+iframe_preload_play+" "}} />
      </div>
    );
  }

  function handlePaymentImprove() {
    window.location.href = `https://${PREFIX_URL}payment.Oryon.com/improve`;
  }

  async function handleUpdateAccount() {
    console.log('handleUpdateAccount');
    const editedData = await updateAccount();
    console.log('editedData: ', editedData);
    setEditUserData(false);
  }

  async function updatePasswordAccount() {
    // event.preventDefault();
    const data = {
      "email": formData.email,
      "password": formData.password,
      "new_password": formData.new_password
    };
    const endpoint = `/update-password-account`;
    try {
      const res = await request('POST', endpoint, data, { 'content-type': ContentTypes.json });
      const { result } = res.data;
      console.log(res);
      if (result) {
        console.log(result);
        setNotification({
          status: true,
          className: 'success',
          message: 'La contraseña se ha modificado correctamente.'
        });
        setEditPasswordData(false)
        return true;
        // props.history.push('/login')
      } else {
        setNotification({
          status: true,
          className: 'danger',
          message: 'Error al ingresar los datos.'
        });
        console.log('res: ',res);
        return false;
      }
    } catch (err) {
      console.log(err)
      setNotification({
        status: true,
        className: 'danger',
        message: 'Error al ingresar los datos.'
      });
    }
  }

  async function handleUpdatePasswordAccount() {
    if(formData.email && 
      formData.password && 
      formData.new_password &&
      formData.repeat_password) {
        if(formData.new_password === formData.repeat_password) {
          const editedPasswordData = await updatePasswordAccount();
          console.log('editedPasswordData: ',editedPasswordData);
          setEditPasswordData(false);
        } else {
          setNotification({
            status: true,
            className: 'danger',
            message: 'Los campos de nueva contraseña y repetir contraseña deben ser iguales.'
          });
        }

    } else {
      setNotification({
        status: true,
        className: 'danger',
        message: 'Debe completar todos los campos.'
      });   
    }
  }

  async function getSubscription(stripe_subscription_id) {

  }

  return (
    <div className='profile-wrapper'>
      <Section maxWidth='small'>
        <Row>
          <Col><H2 className="text-primary">Tu cuenta</H2></Col>
        </Row>
        <Row>
          <Col><H3>Datos personales</H3></Col>
        </Row>
        {updateSession ? setSessions() : null}

        {/* FORM */}
        {editUserData ? (
          <div>
            {(notification.status) ? (
              <Row>
                <Col>
                  <Notification className={notification.className}>{notification.message}</Notification>
                </Col>
              </Row>) : null
            }
            <Row>
              <Col>
                <Input name='firstname' value={formData.firstname} placeholder={t('sign_up.firstname')} onChange={handleInputChange}/>
              </Col>
            </Row>
            <Row>
              <Col>
                <Input name='lastname' value={formData.lastname} placeholder={t('sign_up.lastname')} onChange={handleInputChange}/>
              </Col>
            </Row>
            <Row>
              <Col className='item-user-data'>
                <div className='label'>
                  {t('sign_up.email')}:
                </div>
                <div className='value'>
                  <H5>{formData.email}</H5> 
                </div>
              </Col>
            </Row>

            <Row>
              <Col>
                <Button label={'Guardar cambios'} action={()=> handleUpdateAccount()}/>
                <Button label={'Cancelar'} action={()=> setEditUserData(false)}/>
              </Col>
            </Row>
          </div>
        ) : (
          <div>
            {(notification.status) ? (
              <Row>
                <Col>
                  <Notification className={notification.className}>{notification.message}</Notification>
                </Col>
              </Row>) : null
            }
            <Row>
              <Col className='item-user-data'>
                <div className='label'>
                  {t('sign_up.firstname')}/{t('sign_up.lastname')}:
                </div>
                <div className='value'>
                  <H5>{formData.firstname} {formData.lastname}</H5>
                </div>
              </Col>
            </Row>
            <Row>
              <Col className='item-user-data'>
                <div className='label'>
                  {t('sign_up.email')}:
                </div>
                <div className='value'>
                  <H5>{formData.email}</H5>
                </div>
              </Col>
            </Row>

            {!editPasswordData ? (
              <Row>
                <Col>
                  <Button label={'Modificar perfil'} action={()=> setEditUserData(true)}/> 
                  <Button label={'Cambiar contraseña'} action={()=> setEditPasswordData(true)} />
                </Col>
              </Row>) : null
            }

          </div>
        )}

        {editPasswordData ? (
          <div>
            <Row>
              <Col>
                <Input name='password' type='password' value={formData.password} placeholder='Ingresa contraseña anterior' onChange={handleInputChange}/>
              </Col>
            </Row>
            <Row>
              <Col>
                <Input name='new_password' type='password' value={formData.new_password} placeholder='Ingresa nueva contraseña' onChange={handleInputChange}/>
              </Col>
            </Row> 
            <Row>
              <Col>
                <Input name='repeat_password' type='password' value={formData.repeat_password} placeholder='Repite nueva contraseña' onChange={handleInputChange}/>
              </Col>
            </Row>
            <Row>
              <Col>
                <Button label={'Modificar contraseña'} action={()=> handleUpdatePasswordAccount()}/> <Button label={'Cancelar'} action={()=> setEditPasswordData(false)}/>
              </Col>
            </Row>         
          </div>
        ) : null }

        <Row>
          <Col>
            <LineDivisor />
          </Col>
        </Row>
        <Row>
          <Col><H3>Tu plan</H3></Col>
        </Row>
        <Row>
          <Col><H5>Este es el plan que tienes contratado</H5></Col>
        </Row>
        <Row>
          <Col>
            { !canceledSubscription ? (
              <div className='box-plan'>
              <div className='box-plan-header'>PLAN {(subscriptionPlan==='month') ? 'MENSUAL' : 'ANUAL'}</div>
              <div className='box-plan-content'>
                <Row>
                  <Col>{`Tu próxima factura se emitirá el ${currentPerdiodEnd}`}.</Col>
                </Row>
                <Row>
                  <Col>
                    <Button className='primary' label={'Mejora tu plan'} action={()=>handlePaymentImprove()}/>
                  </Col>
                </Row>
              </div>
            </div>) : (
              <div className='box-plan'>
                <div className='box-plan-header'>PLAN  CANCELADO</div>
                <div className='box-plan-content'>
                  
                  <Row>
                    <Col>
                      <Button className='primary' label={'REACTIVAR'} action={()=>handlePaymentImprove()}/>
                        </Col>
                  </Row>
                </div>
              </div>
            )}
          </Col>
        </Row>

        <Row>
          <Col>
            <LineDivisor />
          </Col>
        </Row>
        <Row>
          <Col><H3>Método de pago</H3></Col>
        </Row>
       {/* <Row>
          <Col>
            <div className='plan-card-wrapper'>
              <div className='icon-card-mastercad'></div>
              <div className='content'>
                Tarjeta débito MasterCard acabada en 3020. Caduca: 07/2022.
              </div>
            </div>
          </Col>
        </Row>*/}
        <Row>
          <Col>
            <a className='btn' href={`https://${PREFIX_URL}payment.Oryon.com/payment-change-method`}>
              Actualizar método de pago
            </a>
          </Col>
        </Row>
        <Row>
          <Col>
            <LineDivisor />
          </Col>
        </Row>        
        <Row>
          <Col>
            Quiero darme de baja. <a href={`https://${PREFIX_URL}payment.Oryon.com/cancel`}>Cancelar plan</a>
          </Col>
        </Row>
      </Section>
    </div>
  );
}

export default Profile;