import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
// import { useDispatch } from 'react-redux';
// import { setItems } from '../../../store/items/ItemsActions';
// import { getStatus } from '../../../store/api/api';
import { request, requestSwitcherAPI, ContentTypes } from '../../../store/api/api';
import { useTranslation } from "react-i18next";
import { Section, Row, Col, Input, H3, Notification, JS, Button } from 'ou-ui-pack';
import { PREFIX_URL } from '../../../store/constants';

function SignUp(props) {
  const { account_role } = useParams();
  const { t } = useTranslation('common');
  const created_at = new Date().toISOString().slice(0, 19).replace('T', ' ');
  // const { items } = useSelector((state: any) => state.default.items)
  // const dispatch = useDispatch();
  const [checked, setChecked] = React.useState(false);
  const handleChange = () => {
    setChecked(!checked);
  };
  const [formData, setFormData] = useState({
    firstname: '',
    lastname: '',
    email: '',
    password: '',
    repeat_password: ''
  });
  const [notification, setNotification] = useState({status: false, style: '', message: ''});
  const [loading, setLoading] = useState(false);
  // const [accountCreated, setAccountCreated] = useState();
  // console.log('created_at: ',created_at);

  const handleInputChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name] : event.target.value
    })
  }

  useEffect(() => {
    /*if(!items.id) {
      dispatch(setItems({ id:1, title: 'Example item title'}));
      getStatus();
    }*/
    console.log('account_role: ',account_role);
  });

  async function createAccount(event) {
    event.preventDefault();
    setLoading(true);

    if((formData.firstname==='') ||
      (formData.lastname==='') ||
      (formData.email==='') ||
      (formData.repeat_email==='') || ((formData.email!=='') && (formData.repeat_email!=='') && (formData.repeat_email!==formData.email)) || 
      (formData.password==='') ||
      (formData.repeat_password==='')) {
      setNotification({
        status: true,
        className: 'danger',
        message: 'Debe completar todos los campos.'
      });
      if (formData.repeat_email!==formData.email) {
        setNotification({
          status: true,
          className: 'danger',
          message: 'Los campos "Email" y "Repetir Email" deben ser iguales.'
        });
      }
      setLoading(false);
    } else {
      setNotification({
        status: false,
        className: '',
        message: ''
      });
      if(!checked) {
        setNotification({
          status: true,
          className: 'danger',
          message: 'Debe aceptar los términos y condiciones.'
        });
        setLoading(false);
      } else {
        const account_role_admin = account_role ? account_role : 'employee';
        const data = {
          "account_email": formData.email.toLowerCase(),
          "account_password": formData.password,
          "account_firstname": formData.firstname,
          "account_lastname": formData.lastname,
          "account_role": account_role_admin
        };
  
        const endpoint = `/account/create`;
        try {
          const res = await request('POST', endpoint, data, { 'content-type': ContentTypes.json });
          const { result } = res.data;
          console.log(res.data);

          if (result) {
              // setAccountCreated(true);
            // loginAccount({email: formData.email, password: formData.password});
            sendEmail(data);
          } else {
            if(res.data.error) {
              setNotification({
                status: true,
                className: 'danger',
                message: 'El email ya existe en nuestra base de datos.'
              });
            } else {
              setNotification({
                status: true,
                className: 'danger',
                message: 'Error al ingresar los datos.'
              });
            }
            setLoading(false);
          }
        } catch (err) {
          console.log(err)
          setNotification({
            status: true,
            className: 'danger',
            message: 'Error al ingresar los datos.'
          });
          setLoading(false);
        }
      }
    }
  }

  async function loginAccount({email, password}) {
    // event.preventDefault();
    const data = {
      "account_email": email.toLowerCase(),
      "account_password": password
    };

    const endpoint = `/account/login`;
    try {
      const res = await request('POST', endpoint, data, { 'content-type': ContentTypes.json });
      const user = res.data;
      if (user && user.account_id) {
        JS.setSession({ user });
        const session_token = JS.getCookie({cname:'session_token'});
        sessionStorage.setItem('session_token', session_token);

        window.location.href = `./sessions`;

        /*const user_session = {...user, lp_token: lp_token}
        console.log('user_session: ',user_session);
        const session_token = 'im-'+JS.setSession({ user: user_session });
        // Save Cookie to local machine
        JS.setCookie({cname:'session_token', cvalue: session_token});
        // Store sessionStorage for access to iframes from externals domains
        sessionStorage.setItem('session_token', session_token);
        window.location.href = `https://admin.improovia.com`;
        //window.location.href = `https://${PREFIX_URL}account.Oryon.com/sessions`;*/
      }
    } catch (err) {
      console.log(err)
    }
  };

  async function sendEmail(dataForm) {
    // event.preventDefault();
    const firstname = dataForm.account_firstname;
    const data = {
      "email_to": dataForm.account_email,
      "subject": "Registro de usuario",
      "message": `<h2>¡Felicidades, te has registrado correctamente!</h2><br><br>Puedes acceder a tu panel de control a través del siguiente enlace https://account.improovia.com`
    };

    const endpoint = `/email-send`;
    try {
      const res = await requestSwitcherAPI('EMAIL','POST', endpoint, data, { 'content-type': ContentTypes.json });
      const user = res.data;
    } catch (err) {
      console.log(err)
    }
    setTimeout(() => {
      window.location.href = `../`;
    }, 2000);
    /*
    const data = {
      "email": dataForm.account_email,
      "firstname": dataForm.account_firstname,
      "lastname":  dataForm.account_lastname,
      "message": `Gracias por registrarte`
    };

    const endpoint = `/im`;
    try {
      const res = await requestSwitcherAPI('EMAIL','POST', endpoint, data, { 'content-type': ContentTypes.json });
      const user = res.data;
      window.location.href = `../`;
    } catch (err) {
      console.log(err)
    }*/
  };

  /*if(accountCreated) {
    return (
      <Section maxWidth='small'>
        <Row>
          <Col><H3>{t('sign_up.title_verify')}</H3></Col>
        </Row>
        <Row>
          <Col>
            {t('sign_up.description_verify')}
          </Col>
        </Row>
        <Row>
          <Col>
            <Link to='/login'>Ir a login</Link>
          </Col>
        </Row>
      </Section>
    )
  }*/

  return (
    <div className='center'>
      <Section maxWidth='small'>
        <Row>
          <Col><H3>{t('sign_up.title')}</H3></Col>
        </Row>
        <form onSubmit={ e =>createAccount(e)}>
          {(notification.status) ? (
            <Row>
              <Col>
                <Notification className={notification.className}>{notification.message}</Notification>
              </Col>
            </Row>) : null
          }
          <Row>
            <Col>
              <Input type='text' name='firstname' value={formData.firstname} placeholder={t('sign_up.firstname')} onChange={handleInputChange}/>
            </Col>
          </Row>
          <Row>
            <Col>
              <Input type='text' name='lastname' value={formData.lastname} placeholder={t('sign_up.lastname')} onChange={handleInputChange}/>
            </Col>
          </Row>
          <Row>
            <Col>
              <Input type='email' name='email' value={formData.email} placeholder={t('sign_up.email')} onChange={handleInputChange}/>
            </Col>
          </Row>
          <Row>
            <Col>
              <Input type='email' name='repeat_email' value={formData.repeta_email} placeholder={'Repetir email'} onChange={handleInputChange}/>
            </Col>
          </Row>
          <Row>
            <Col>
              <Input type='password' name='password' value={formData.password} placeholder={t('sign_up.password')} onChange={handleInputChange}/>
            </Col>
          </Row>
          <Row>
            <Col>
              <Input type='password' name='repeat_password' value={formData.repeat_password} placeholder={t('sign_up.repeat_password')} onChange={handleInputChange}/>
            </Col>
          </Row>
          <Row>
            <Col>
              <input
                type="checkbox"
                className='space'
                checked={checked}
                onChange={handleChange} 
              /> {t('sign_up.accept_terms_conditions')} <a href="https://account.improovia.com/terminos-condiciones.pdf" target="_blank" rel="noreferrer">{t('sign_up.link_terms_conditions')}</a>
            </Col>
          </Row>
          <Row>
            <Col>
              <button className='btn primary'>{t('sign_up.sign_up')}</button>
              {/*<Button label={t('sign_up.sign_up')} className='primary' loading={loading} action={null} />*/}
            </Col>
          </Row>
        </form>
        <Row>
          <Col>
            {t('sign_up.do_you_have_account')} <Link to='/login'>{t('sign_up.login')}</Link>
          </Col>
        </Row>
      </Section>
    </div>
  );
}

export default SignUp;