import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { request, ContentTypes } from '../../../store/api/api';
import { useTranslation } from "react-i18next";
import { Section, Row, Col, Button, Input, H3, Notification, JS } from 'im-ui-pack';
import { PREFIX_URL } from '../../../store/constants';

function Login() {
  const {t} = useTranslation('common');
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });
  const [notification, setNotification] = useState({status: false, style: '', message: ''});
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleInputChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name] : event.target.value
    })
  }

  useEffect(()=> {
    const listener = event => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        console.log("Enter presionado");
        event.preventDefault();
        // navigate to singUp
        submitLogin();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };  
  })

  function togglePassword() {
    setShowPassword(!showPassword);
  }

  async function submitLogin(event) {
    // event.preventDefault();
    setLoading(true);
    const data = {
      "account_role": "customer",
      "account_email": formData.email.toLowerCase(),
      "account_password": formData.password
    };

    const endpoint = `/account/login`;
    try {
      const res = await request('POST', endpoint, data, { 'content-type': ContentTypes.json });
      const user = res.data;

      if (user && user.account_id) {
        JS.setSession({ user });
        //const session_token = 'im-'+JS.setSession({ user });
        // Save Cookie to local machine
       // JS.setCookie({cname:'session_token', cvalue: session_token});
        // Store sessionStorage for access to iframes from externals domains
        //sessionStorage.setItem('session_token', session_token);
        // window.location.href = `https://${user.role}.improovia.com`;
        //const token =  JS.encodeString(JSON.stringify(session_token));
        const session_token = JS.getCookie({cname:'session_token'});
        sessionStorage.setItem('session_token', session_token);

        window.location.href = `./sessions`;
      } else {
        setNotification({
          status: true,
          className: 'danger',
          message: 'Usuario o contraseña incorrecto'
        });
        setLoading(false);
      }
    } catch (err) {
      console.log(err)
      setLoading(false);
    }
  };

  return (
    <div className='center'>
      <Section maxWidth='small'>
        <Row>
          <Col><H3>{t('login.title')}</H3></Col>
        </Row>
          {(notification.status) ? (
            <Row>
              <Col>
                <Notification className={notification.className}>{notification.message}</Notification>
              </Col>
            </Row>) : null
          }
          <Row>
            <Col>
              <Input type='email' name='email' value={formData.email} placeholder={t('login.email')} onChange={handleInputChange}/>
            </Col>
          </Row>
          <Row className='show-password-wrapper'>
            <Col>
              <Input type={showPassword ? 'text' : 'password'} name='password' value={formData.password} placeholder={t('login.password')} onChange={handleInputChange}/>
            </Col>
          </Row>
          <Row>
            <Col>
              <input
                type="checkbox"
                className='space'
                onClick={togglePassword}
              />
              Mostrar contraseña
            </Col>
          </Row>
          <Row>
            <Col>
              {/*<Button label={t('login.login')} className='primary' action={e => submitLogin(e)}/>*/}
              <Button label={t('login.login')} className='primary' loading={loading} action={e => submitLogin(e)} />
            </Col>
          </Row>
        <Row>
          <Col>
            {t('login.forgot_password')} <Link to='/forgotPassword'>{t('login.remember_password')}</Link>
          </Col>
        </Row>
        <Row>
          <Col>
            {t('login.you_do_not_have_an_account')} <Link to='/signUp'>{t('login.sign_up')}</Link>
          </Col>
        </Row>
      </Section>
    </div>
  );
}

export default Login;