/* Pages */
// import ErrorPage from '../containers/errorPage/ErrorPage';
import IRoute from '../models/route';

// Home
import Login from '../pages/public/login/Login';
import SignUp from '../pages/public/signUp/SignUp';
import ForgotPassword from '../pages/public/forgotPassword/ForgotPassword';
import ChangePassword from '../pages/public/forgotPassword/ChangePassword';
import Sessions from '../pages/public/sessions/Sessions';
import Close from '../pages/public/close/Close';
import Profile from '../pages/private/profile/Profile';

// Private
// import Search from '../pages/private/search/Search';

/* Layouts */
import PublicLayout from '../layouts/publicLayout/PublicLayout';
import PrivateLayout from '../layouts/privateLayout/PrivateLayout';

export const routes: IRoute[] = [
  {
    path: '/close',
    name: 'Close Page',
    component: Close,
    layout: PublicLayout,
    exact: true
  },
  {
    path: '/sessions',
    name: 'Sessions Page',
    component: Sessions,
    layout: PublicLayout,
    exact: true
  }, 
  {
    path: '/profile',
    name: 'Profile Page',
    component: Profile,
    layout: PrivateLayout,
    exact: true
  },
  {
    path: '/forgotPassword',
    name: 'Forgot Password Page',
    component: ForgotPassword,
    layout: PublicLayout,
    exact: true
  },
  {
    path: '/recovery/:email_to_encode',
    name: 'Change Password Page',
    component: ChangePassword,
    layout: PublicLayout,
    exact: true
  },
  {
    path: '/signup',
    name: 'Sign Up Page',
    component: SignUp,
    layout: PublicLayout,
    exact: true
  },
  {
    path: '/signup/:account_role',
    name: 'Sign Up Page',
    component: SignUp,
    layout: PublicLayout,
    exact: true
  },
  {
    path: '/login',
    name: 'Home Page',
    component: Login,
    layout: PublicLayout,
    exact: true
  },
  {
    path: '/',
    name: 'Home Page',
    component: Login,
    layout: PublicLayout,
    exact: true
  }
];