import axios from 'axios';
import { API_URL, API_URL_EMAIL } from '../constants';
// import { getUsersAdapter, getUserAdapter } from './adapter/user/userAdapter';

export const API = API_URL;
// export const API = 'https://bjevt80zgd.execute-api.eu-central-1.amazonaws.com/dev';
export const API_EMAIL_SERVER = 'https://server.improovia.com';
export const ContentTypes = {
	json: 'application/json',
	formData: 'multiplart/form-data',
	html: 'text/html',
};

// More info: https://github.com/axios/axios
export function request(method, endpoint, data = {}, headers = { 'content-type': ContentTypes.json }, other) {
	const url = `${API}${endpoint}`;
  console.log('api.js request: url: ', url);
	const options = {
		method,
		url,
		data,
		headers,
		...other,
	};
	return axios(options);
}

function getAPISwitcher(api_switcher) {
  switch (api_switcher) {
    case 'EMAIL':
      return API_EMAIL_SERVER;
    case 'ACCOUNT':
      return API;
    default:
      //Declaraciones ejecutadas cuando ninguno de los valores coincide con el valor de la expresión
      return API_URL;
  }
}

// More info: https://github.com/axios/axios
export function requestSwitcherAPI(api_switcher, method, endpoint, data = {}, headers = { 'content-type': ContentTypes.json }, other) {
  const API_CHOOSED = getAPISwitcher(api_switcher);
  const url = `${API_CHOOSED}${endpoint}`;
  const options = {
    method,
    url,
    data,
    headers,
    ...other,
  };
  return axios(options);
}

/*export async function getStatus() {
  console.log('getStatus : API_URL', API_URL);
  return await axios.get(`https://s73myi7y3c.execute-api.eu-central-1.amazonaws.com/test/status`, { 'content-type': ContentTypes.json })
    .then((response) => {
      console.log('response: ', response);
      //const data = getUsersAdapter(response);
      return response; //data;
    })
    .catch((error) => {
      console.log(error);
      return null;
    });
};*/
/*

export async function createUser(data) {
  const params = {
    "email": data.email,
    "password": data.password
  };
  return await axios.patch(`${API_URL}/user`, params)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
      return null;
    });
};

export async function removeUser(email) {
  const params = {
    data: {
      email
    }
  };
  return await axios.delete(`${API_URL}/user`, params)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
      return null;
    });
};

export async function updateUser(data) {
  const params = {
    email: data.email,
    password: data.password
  };

  return await axios.post(`${API_URL}/user`, params)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
      return null;
    });
};

export async function getUser(email) {
  const params = {
    email
  };
  return await axios.put(`${API_URL}/user`, params)
    .then((response) => {
      return getUserAdapter(response);
    })
    .catch((error) => {
      console.log(error);
      return null;
    });
};
*/