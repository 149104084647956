import React from 'react';
import { Section, Row, Col, Loading, JS } from 'im-ui-pack';
import './Sessions.css';
import { PREFIX_URL } from '../../../store/constants';

function Sessions() {
  const session = JS.getSession();
  const { account } = session;
  // const iframe_admin = `<iframe src='http://localhost:8084/session/index.html' />`;
  
 //const iframe_preload_play = `<iframe src='https://${PREFIX_URL}play.rebaila.com/session/index.html' />`;

  const redirectByRole = (role) => {
    switch (role) {
      case 'admin':
        return `https://${PREFIX_URL}admin.improovia.com/dashboard`;
      case 'employee':
        return `https://${PREFIX_URL}employee.improovia.com/dashboard`;
      case 'professional':
        return `https://${PREFIX_URL}professional.improovia.com/dashboard`;
      case 'company':
        return `https://${PREFIX_URL}company.improovia.com/dashboard`;
      default:
        return '';
    }
  }

  setTimeout(function () {
    const session = JS.getSession();
    window.location.href = redirectByRole(session.account.account_role);
    /*if(session.account.onboarding==='empty') {
      window.location.href = `https://${PREFIX_URL}account.improovia.com`;
    } else {
      window.location.href = redirectByRole(account.role);
    }*/
  }, 3000);

  const handleRedirection = () => {
    const { account_role } = account;
    switch (account_role) {
      case 'admin': {
        const iframe_admin = `<iframe src='https://${PREFIX_URL}admin.improovia.com/session/index.html' />`;
        return <div className='display-none' dangerouslySetInnerHTML={{ __html: " "+iframe_admin+" "}} />
      }
      case 'employee': {
        const iframe_employee = `<iframe src='https://${PREFIX_URL}employee.improovia.com/session/index.html' />`;
        return <div className='display-none' dangerouslySetInnerHTML={{ __html: " "+iframe_employee+" "}} />
      }
      case 'company': {
        const iframe_company = `<iframe src='https://${PREFIX_URL}company.improovia.com/session/index.html' />`;
        return <div className='display-none' dangerouslySetInnerHTML={{ __html: " "+iframe_company+" "}} />
      }
      case 'professional': {
        const iframe_professional = `<iframe src='https://${PREFIX_URL}professional.improovia.com/session/index.html' />`;
        return <div className='display-none' dangerouslySetInnerHTML={{ __html: " "+iframe_professional+" "}} />
      }
      default:
        return ''
    }
  }

  return (
    <div>
      <Section maxWidth='small'>
        <Row>
          <Col>
            <Loading visible={true} className='center'/>
          </Col>
        </Row>
      </Section>
      {account.account_role ? handleRedirection() : null}
      {/*<div className='display-none' dangerouslySetInnerHTML={{ __html: " "+iframe_preload_play+" "}} />*/}
    </div>
  );
}

export default Sessions;