import React from 'react';
import { Section, Row, Col, Loading, JS } from 'ou-ui-pack';
import './Close.css';
import { PREFIX_URL } from '../../../store/constants';

function Sessions() {
  const session = JS.getSession();

  const iframe_admin = `<iframe src='https://${PREFIX_URL}admin.improovia.com/session/reset.html' />`;
  const iframe_company = `<iframe src='https://${PREFIX_URL}company.improovia.com/session/reset.html' />`;
  const session_token = '';

  // Save Cookie to local machine
  JS.setCookie({cname:'session_token', cvalue: session_token});
  // Store sessionStorage for access to iframes from externals domains
  sessionStorage.setItem('session_token', session_token);


  setTimeout(function () {
    window.location.href = `https://${PREFIX_URL}account.improovia.com`;
  }, 3000);

  return (
    <div>
      <Section maxWidth='small'>
        <Row>
          <Col>
            <Loading visible={true} className='center'/>
          </Col>
        </Row>
      </Section>
      <div className='display-none' dangerouslySetInnerHTML={{ __html: " "+iframe_admin+" "}} />
      <div className='display-none' dangerouslySetInnerHTML={{ __html: " "+iframe_company+" "}} />
    </div>
  );
}

export default Sessions;