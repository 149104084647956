import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
// import { useSelector, useDispatch } from 'react-redux';
// import { setItems } from '../../../store/items/ItemsActions';
//import { getStatus } from '../../../store/api/api';
import { requestSwitcherAPI, ContentTypes } from '../../../store/api/api';
import { useTranslation } from "react-i18next";
import { Section, Row, Col, Button, Input, H3, P, Notification, JS } from 'ou-ui-pack';
// import { PREFIX_URL } from '../../../store/constants';
const PREFIX_URL = '';

function ForgotPassword() {
  const { t } = useTranslation('common');
  // const { items } = useSelector((state: any) => state.default.items)
  const [formData, setFormData] = useState({
    email: ''
  });
  const [notification, setNotification] = useState({status: false, style: '', message: ''});

  useEffect(() => {
    /*if(!items.id) {
      dispatch(setItems({ id:1, title: 'Example item title'}));
      getStatus();
    }*/
  });

  const handleInputChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name] : event.target.value
    })
  }

  async function sendEmail(payload) {
    const data = {
      "email_to": payload.email_to,
      "subject": payload.subject,
      "message": payload.message
    };
    const endpoint = `/email-send`;
    try {
      const response = await requestSwitcherAPI('EMAIL','POST', endpoint, data, { 'content-type': ContentTypes.json });
      console.log('response: ', response);
      if(response.data) {
        setNotification({
          status: true,
          className: 'success',
          message: 'Se ha enviado un email con el enlace para cambiar tu contraseña. Si no aparece verifica tu bandeja de SPAM.'
        });
      }

    } catch (err) {
      console.log(err)
    }
  };

  async function handleSubmitForgotPassword(event) {
    // event.preventDefault();
    const email_to = formData.email.toLowerCase();
    const email_to_encode = JS.encodeString(email_to);
    const payload = {
      email_to: email_to,
      subject: 'Recuperar acceso',
      message: `Haz click en el siguiente enlace para recuperar contraseña. https://${PREFIX_URL}account.improovia.com/recovery/${email_to_encode}`
    };

    console.log(payload);
    sendEmail(payload);
    /*
    const data = {
      "email": formData.email.toLowerCase()
    };

    const endpoint = `/exist-email`;
    try {
      const res = await requestSwitcherAPI('ACCOUNT','POST', endpoint, data, { 'content-type': ContentTypes.json });
      const user = res.data;
      if (user && user.id) {
        console.log('user:',user);
        // enviar email con link 
        const email_to = data.email;
        const email_to_encode = JS.encodeString(email_to);
        const payload = {
          email_to: email_to,
          subject: 'Recuperar acceso',
          message: `Haz click en el siguiente enlace para recuperar contraseña. https://${PREFIX_URL}account.improovia.com/recovery/${email_to_encode}`
        };
        sendEmail(payload);
      } else {
        setNotification({
          status: true,
          className: 'danger',
          message: 'Email no existe!'
        });
      }
    } catch (err) {
      console.log(err)
    }*/
  };

  return (
    <div className='center'>
      <Section maxWidth='small'>
        <Row>
          <Col><H3>{t('forgot_password.title')}</H3></Col>
        </Row>
        <Row>
          <Col><P>{t('forgot_password.subtitle')}</P></Col>
        </Row>
          {(notification.status) ? (
            <Row>
              <Col>
                <Notification className={notification.className}>{notification.message}</Notification>
              </Col>
            </Row>) : null
          }
          <Row>
            <Col>
              <Input type='email' name='email' value={formData.email} placeholder={t('forgot_password.email')} onChange={handleInputChange}/>
            </Col>
          </Row>
          <Row>
            <Col>
              <Button label={t('forgot_password.send')} className='primary' action={e => handleSubmitForgotPassword(e)}/>
            </Col>
          </Row>

        <Row>
          <Col>
            {t('forgot_password.do_you_have_account')} <Link to='/login'>{t('forgot_password.login')}</Link>
          </Col>
        </Row>
      </Section>
    </div>
  );
}

export default ForgotPassword;