import React from 'react';
import { Header, Container, Footer} from 'im-ui-pack';
import sessionLinks from '../../store/mock/sessionLinks.json';

function PublicLayout(props: any) {
  return (
    <div className="public-layout bg-public">
      <Header sessionLinks={sessionLinks} />
      <Container>
        {props.children}
      </Container>
      <Footer />
    </div>
  );
}

export default PublicLayout